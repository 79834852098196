<template>
  <div class="mini-container columnd space-between">
    <div class="rowd gap8 align-center">
      <inline-svg :src="require('@/assets/Icons/security.svg')" />
      <h2 class="f16-700 fBold c-lightOrange">2Factor Authentication</h2>
    </div>
    <p class="c-darkGrey f14-400 text-start">
      when ever you signin to your account, you’ll need to enter your both
      password and your seccond factor code.
    </p>
    <div class="columnd gap24">
      <div class="columnd gap16">
        <div class="selectCoin rowd space-between">
          <span
            :class="{ selected: otpType === 'Email' }"
            @click="otpType = 'Email'"
            style="flex: 1"
            class="f12-700 c-medGrey"
            >Email</span
          >
          <span
            :class="{ selected: otpType === 'SMS' }"
            @click="otpType = 'SMS'"
            style="flex: 1"
            class="f12-700 c-medGrey"
            >SMS</span
          >
          <span
            :class="{ selected: otpType === 'Google' }"
            @click="otpType = 'Google'"
            style="flex: 1"
            class="f12-700 c-medGrey"
            >Google Authenticator</span
          >
          <div
            class="move-bg"
            :class="[
              { email: otpType === 'Email' },
              { sms: otpType === 'SMS' },
              { google: otpType === 'Google' },
            ]"
          ></div>
        </div>
        <h2 class="f16-700 c-darkBlue fBold text-start">
          We’ve send a verifivation code to your Email!
        </h2>
        <p class="c-darkGrey f14-400 text-start">
          Please write 6 digit code in here
        </p>
      </div>
      <CodeInput class="w100 code" />
      <span class="f14-700 c-darkGrey align-self-center"> 12:26 </span>
    </div>
    <button class="primary-btn">Submit</button>
  </div>
</template>

<script>
import CodeInput from "../../Tools/CodeInput.vue";
export default {
  name: "ChangeOtp",
  components: {
    CodeInput,
  },
  data() {
    return {
      otpType: "Email",
    };
  },
};
</script>

<style lang="scss" scoped>
.email {
  left: 4px;
}
.sms {
  left: 33%;
}
.google {
  left: 66%;
}
.move-bg {
  background: white !important;
  position: absolute;
  width: 32%;
  height: 32px;
  top: 4px;
  border-radius: 4px;
  z-index: 0;
  transition: 500ms;
}
.selected {
  color: var(--darkBlue) !important;
}
.selectCoin {
  position: relative;
  height: 40px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 2;
  }
}
.primary-btn {
  max-width: 190px;
  align-self: flex-end;
}
input {
  background: transparent;
}
.mini-container {
  max-width: 588px;
  flex: 1;
  padding: 32px;
  min-height: 515px;
  min-width: 300px;
}
</style>
