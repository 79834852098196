<template>
  <div class="mini-container columnd space-between">
    <div class="columnd gap24 w100">
      <div class="rowd gap8 align-center">
        <inline-svg :src="require('@/assets/Icons/lock.svg')" />
        <h2 class="f16-700 fBold c-lightOrange">Change pass</h2>
      </div>
      <div class="content columnd gap32">
        <span class="f14-400 c-darkGrey text-start"
          >Please Change your password
        </span>
        <div class="inputs-container columnd gap16">
          <div class="selectCoin">
            <span class="f12-700 c-darkGrey text-start">Old Password</span>
            <div class="rowd w100 space-between">
              <input placeholder="Password" class="w100" type="text" />
              <inline-svg :src="require('../../../assets/Icons/eye.svg')" />
            </div>
          </div>
          <div class="selectCoin">
            <span class="f12-700 c-darkGrey text-start">New Password</span>
            <div class="rowd w100 space-between">
              <input placeholder="Password" class="w100" type="text" />
              <inline-svg :src="require('../../../assets/Icons/eye.svg')" />
            </div>
          </div>
          <span class="link12-400 c-darkBlue text-start">Forgot password?</span>
        </div>
      </div>
    </div>
    <button class="secondary-btn f14-700">Change password</button>
  </div>
</template>

<script>
export default {
  name: "ChangePass",
};
</script>

<style lang="scss" scoped>
.secondary-btn {
  max-width: 190px;
  align-self: flex-end;
}
input {
  background: transparent;
}
.mini-container {
  max-width: 588px;
  flex: 1;
  padding: 32px;
  min-height: 515px;
  min-width: 300px;
}
</style>
