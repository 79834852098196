<template>
  <div class="settings rowd gap24 w100 align-center justify-center wrap">
    <ChangeOtp />
    <ChangePass />
  </div>
</template>

<script>
import ChangePass from "./ChangePass.vue";
import ChangeOtp from "./ChangeOtp.vue";
export default {
  components: {
    ChangePass,
    ChangeOtp,
  },
};
</script>

<style lang="scss" scoped></style>
